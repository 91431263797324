import { useCallback, useEffect, useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

import AvatarWithTitle from 'components/AvatarWithTitle';
import BodyText from 'components/BodyText';
import ContentBox from 'components/ContentBox';
import ContentPanel from 'components/ContentPanel';
import HeaderText from 'components/HeaderText';
import ImportantLink from 'components/ImportantLink';
import PanelBody from 'components/PanelBody';
import Table from 'components/Table';
import TableRow from 'components/TableRow';
import TextWithLabel from 'components/TextWithLabel';
import WithLabel from 'components/WithLabel';

import useConfig from 'hooks/useConfig';

import useTranslation from './hooks/useTranslation';
import useFormName from './hooks/useFormName';
import useFormNotes from './hooks/useFormNotes';

import styles from './styles.module.scss';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import CloseIcon from "components/icons/CloseIcon";
import MoveIcon from "components/icons/MoveIcon";
import AddRadioButtonOption from 'components/AddRadioButtonOption';
import AddDropDownOption from "components/AddDropDownOption";
import AddCheckBoxOption from "components/AddCheckBoxOption";
import TextInput from 'components/TextInput';
import CheckBoxWithTextInput from "components/CheckBoxWithTextInput";
import PanelTitle from "components/PageTitle";
import TextBox from 'components/TextBox';
import RadioButtonWithTextInput from "components/RadioButtonWithTextInput";
import Dropdown from "components/Dropdown";
import Buttonize from "components/Buttonize";
import EditableTextInput from 'components/EditableTextInput';
import CheckPermissionsAlternateElement from 'components/CheckPermissionsAlternateElement';
import EditableTextWithLabel from 'components/EditableTextWithLabel';
import EditableEditor from 'components/EditableEditor';
import { FormTable } from "components/form-table/form-table";

const FormDetailsPanel = ({
    className = '',
    form = {},
    formElements,
    isEditing,
    pendingCheckBoxInputElementOptions,
    pendingDropDownInputElementOptions,
    pendingRadioInputElementOptions,
    resetElement,
    setPendingCheckBoxInputElement,
    setPendingCheckBoxInputElementOptions,
    setPendingConditionalElement,
    setPendingDescriptionElement,
    setPendingDropDownInputElement,
    setPendingDropDownInputElementOptions,
    setPendingRadioInputElement,
    setPendingRadioInputElementOptions,
    setPendingTextAreaInputElement,
    setPendingTextInputElement,
    setPendingTitleElement,
    setResetElements
}) => {
    const { useSchema, useUserId } = useGlobalStateHooks();
    const [schema] = useSchema();
    const [userId] = useUserId();
    const { ACTIVITY_ROUTE, EDIT_FORM_ROUTE } = useConfig();
    const [_errorMessage, setErrorMessage] = useState();
    const {
        ACTIVITY_ASSIGNMENTS,
        ACTIVITY_NAME,
        ASSIGNMENT,
        ASSIGNMENT_TYPE,
        CREATED_BY,
        FORM_NAME,
        IS_ACTIVE,
        NOTES,
        PARENT_FORM,
        // RELATED_FORMS,
        ROOT_FORM
    } = useTranslation();

    const headers = [
        ACTIVITY_NAME,
        ASSIGNMENT,
        ASSIGNMENT_TYPE
    ];

    const {
        activityForms = [],
        createdBy = {},
        id,
        isActive = false,
        name = '',
        notes = ''
    } = form

    const isActiveText = (isActive) ? '✓' : '✕';

    const [savedFormName, saveFormName, resetFormName] = useFormName(schema);
    const [displayedFormName, setFormName] = useState(name);
    const handleFormNameSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;
            const output = handleForm();
            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }
            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    formId: id,
                    userId: userId
                }
            );
            saveFormName(formObject);
        },
        [id, saveFormName, userId]
    );
    useEffect(() => {
        if (!savedFormName) {
            return;
        }
        setFormName(savedFormName);
        resetFormName();
        //eslint-disable-next-line
    }, [resetFormName, savedFormName, setFormName]);

    const [savedFormNotes, saveFormNotes, resetFormNotes] = useFormNotes(schema);
    const [displayedFormNotes, setFormNotes] = useState(notes);
    const handleFormNotesSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;
            const output = handleForm();
            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }
            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    formId: id,
                    userId: userId
                }
            );
            saveFormNotes(formObject);
        },
        [id, saveFormNotes, userId]
    );
    useEffect(() => {
        if (!savedFormNotes) {
            return;
        }
        setFormNotes(savedFormNotes);
        resetFormNotes();
        //eslint-disable-next-line
    }, [resetFormNotes, savedFormNotes, setFormNotes]);

    const handleTitleChange = titleField => {
        setPendingTitleElement(titleField);
    };

    const handleCheckBoxInputChange = checkBoxField => {
        setPendingCheckBoxInputElement(checkBoxField);
    };

    const handleDescriptionChange = descriptionField => {
        setPendingDescriptionElement(descriptionField);
    };

    const handleTextBoxChange = textInputField => {
        setPendingTextInputElement(textInputField);
    };

    const handleTextAreaBoxChange = textAreaInputField => {
        setPendingTextAreaInputElement(textAreaInputField);
    };

    const handleRadioButtonTitleChange = radioButtonInputTitle => {
        setPendingRadioInputElement(radioButtonInputTitle);
    };

    const handleRadioButtonOptionChange = (radioButtonOptions, index) => {
        const mutatedOptions = pendingRadioInputElementOptions;
        mutatedOptions[index].name = radioButtonOptions;
        mutatedOptions[index].label = radioButtonOptions;
        setPendingRadioInputElementOptions(mutatedOptions);
    }

    const handleAddRadioButtonOption = () => {
        const mutatedOptions = pendingRadioInputElementOptions;
        mutatedOptions.push({label: '', name: ''});
        setPendingRadioInputElementOptions(mutatedOptions);
    };

    const handleRemoveRadioButtonOption = (_, index) => {
        const mutatedOptions = pendingRadioInputElementOptions;
        mutatedOptions.splice(index, 1);
        const setOptions = [...mutatedOptions]
        setPendingRadioInputElementOptions(setOptions);
    };

    const handleDropDownTitleChange = dropDownInputTitle => {
        setPendingDropDownInputElement(dropDownInputTitle);
    };

    const handleDropDownOptionChange = (dropDownOptions, index) => {
        const mutatedOptions = pendingDropDownInputElementOptions;
        mutatedOptions[index].label = dropDownOptions;
        mutatedOptions[index].value = dropDownOptions;
        setPendingDropDownInputElementOptions(mutatedOptions);
    }

    const handleRemoveDropDownButtonOption = (_, index) => {
        const mutatedOptions = pendingDropDownInputElementOptions;
        mutatedOptions.splice(index, 1);
        const setOptions = [...mutatedOptions]
        setPendingDropDownInputElementOptions(setOptions);
    };

    const handleAddDropDownOption = () => {
        const mutatedOptions = pendingDropDownInputElementOptions;
        mutatedOptions.push({label: '', value: ''});
        setPendingDropDownInputElementOptions(mutatedOptions);
    };

    const handleAddCheckBoxOption = () => {
        const mutatedOptions = pendingCheckBoxInputElementOptions;
        mutatedOptions.push({label: '', value: ''});
        setPendingCheckBoxInputElementOptions(mutatedOptions);
    };

    const handleRemoveCheckBoxButtonOption = (_, index) => {
        const mutatedOptions = pendingCheckBoxInputElementOptions;
        mutatedOptions.splice(index, 1);
        const setOptions = [...mutatedOptions]
        setPendingCheckBoxInputElementOptions(setOptions);
    };

    const handleCheckBoxOptionChange = (checkBoxOptions, index) => {
        const mutatedOptions = pendingCheckBoxInputElementOptions;
        mutatedOptions[index].name = checkBoxOptions;
        mutatedOptions[index].label = checkBoxOptions;
        setPendingCheckBoxInputElementOptions(mutatedOptions);
    }

    useEffect(() => {
        if(resetElement){
            setResetElements(!resetElement);
            if(document.getElementById('pendingTitle')){document.getElementById('pendingTitle').value = '';}
            if(document.getElementById('pendingDescription')){document.getElementById('pendingDescription').value = '';}
            if(document.getElementById('pendingTextBox')){document.getElementById('pendingTextBox').value = '';}
            if(document.getElementById('pendingRadioTitle')){document.getElementById('pendingRadioTitle').value = '';}
            if(document.getElementById('pendingRadioOne')){document.getElementById('pendingRadioOne').value = '';}
            if(document.getElementById('pendingDropDownTitle')){document.getElementById('pendingDropDownTitle').value = '';}
            if(document.getElementById('pendingDropDownOne')){document.getElementById('pendingDropDownOne').value = '';}
            if(document.getElementById('pendingCheckBoxTitle')){document.getElementById('pendingCheckBoxTitle').value = '';}
            if(document.getElementById('pendingCheckBoxOne')){document.getElementById('pendingCheckBoxOne').value = '';}
            if(document.getElementById('pendingConditional')){document.getElementById('pendingConditional').value = '';}
        }
    }, [resetElement, setResetElements])

    return (
        <ContentPanel className={`${className} ${styles.formDetailsPanel}`}>
            {!isEditing && (
                <PanelBody className={styles.panelBody}>
                    <CheckPermissionsAlternateElement permission={['form_update']}>
                        <EditableTextWithLabel
                            label={FORM_NAME}
                            onSave={handleFormNameSave}
                            text={displayedFormName}
                        />

                        
                        <TextWithLabel label={FORM_NAME} text={displayedFormName} />
                    </CheckPermissionsAlternateElement>

                    <WithLabel className={styles.withLabel} text={CREATED_BY}>
                        <AvatarWithTitle user={{id:createdBy?.id, name:`${createdBy?.firstName} ${createdBy?.lastName}`}} />
                    </WithLabel>

                    <TextWithLabel label={IS_ACTIVE} text={isActiveText} />


                    <CheckPermissionsAlternateElement  permission={['form_update']}>
                        <EditableEditor
                            className={styles.notes}
                            key={`${id}.notes`}
                            onSave={handleFormNotesSave}
                            text={displayedFormNotes}
                            title={NOTES}
                        />

                        <ContentBox
                            className={styles.notes}
                            html={displayedFormNotes}
                            key={`${id}.notes`}
                            title={NOTES}
                        />
                    </CheckPermissionsAlternateElement>


                    <WithLabel className={`${styles.withLabel} ${styles.tableContainer}`} text={ACTIVITY_ASSIGNMENTS}>
                        <Table className={`${className} ${styles.activityTable}`}>
                            <TableRow className={`${className} ${styles.activityTableHeader}`}>
                                {headers.map((item, index) => (
                                    <HeaderText
                                        className={`${className} ${styles.headerText}`}
                                        key={`${item}.${index}`}
                                        text={item}
                                    />
                                ))}
                            </TableRow>

                            {activityForms.map(({activityId, activityName, assignment, assignmentType}) => {
                                const modifiedAssignment = assignment.replace('_', ' ')
                                    .replace('-', ' ')
                                    .toLowerCase()
                                    .split(' ')
                                    .map(val =>
                                        (val.length > 0) ? val.replace(val[0], val[0].toUpperCase()) : ""
                                    )
                                    .join(' ')
                                const modifiedAssignmentType = (assignmentType === '1') ? 'One Completion Required' :
                                        (assignmentType === 'all') ? 'All assignees required to complete' : assignmentType
                                return(
                                    <TableRow className={styles.formTableRow}>
                                        <ImportantLink
                                            className={styles.titleLink}
                                            text={activityName}
                                            to={`${ACTIVITY_ROUTE.replace(':activityId', activityId).replace(':activitySchema', schema)}`}
                                        />
                            
                                        <BodyText className={styles.formCell} text={modifiedAssignment} />
                            
                                        <BodyText className={styles.formCell} text={modifiedAssignmentType} />
                                    </TableRow>
                                )
                            })}
                        </Table>
                    </WithLabel>

                    {form?.parentFormId && 
                        <WithLabel className={styles.withLabel} text={PARENT_FORM}>
                            <ImportantLink
                                className={styles.titleLink}
                                text={form?.parentForm}
                                to={`${EDIT_FORM_ROUTE.replace(':formId', form?.parentFormId).replace(':formSchema', schema)}`}
                            />
                        </WithLabel>
                    }

                    {form?.rootFormId && 
                        <WithLabel className={styles.withLabel} text={ROOT_FORM}>
                        <ImportantLink
                            className={styles.titleLink}
                            text={form?.rootForm}
                            to={`${EDIT_FORM_ROUTE.replace(':formId', form?.rootFormId).replace(':formSchema', schema)}`}
                        />
                        </WithLabel>
                    }
                </PanelBody>
            )}

            {isEditing && (
                <PanelBody className={styles.optionsSidePanel}>
                    <Droppable droppableId={'newFormElements'} key={'newFormElements'}>
                        {(provided, _snapshot) => {
                            return (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <PanelTitle text={'Text Components'} />
                                    
                                    <Draggable
                                        draggableId={'titleText'}
                                        index={1}
                                        key={'titleText'}
                                    >
                                        {(provided, _snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                        userSelect: "none",
                                                        ...provided.draggableProps.style
                                                    }}
                                                >
                                                    <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                        <span
                                                            className={styles.icon}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <MoveIcon className={styles.iconComponent}/>
                                                        </span>

                                                        <TextInput
                                                            id={'pendingTitle'}
                                                            label={'Title'}
                                                            onChange={handleTitleChange}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>

                                    <Draggable
                                        draggableId={'normalText'}
                                        index={2}
                                        key={'normalText'}
                                    >
                                        {(provided, _snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                        userSelect: "none",
                                                        ...provided.draggableProps.style
                                                    }}
                                                >
                                                    <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                        <span
                                                            className={styles.icon}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <MoveIcon className={styles.iconComponent}/>
                                                        </span>
                                                        
                                                        <TextBox
                                                            id={'pendingDescription'}
                                                            label={'Description or Instructions'}
                                                            onChange={handleDescriptionChange}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>

                                    <PanelTitle text={'Form Components'} />

                                    <Draggable
                                        draggableId={'textInput'}
                                        index={3}
                                        key={'textInput'}
                                    >
                                        {(provided, _snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                        userSelect: "none",
                                                        ...provided.draggableProps.style
                                                    }}
                                                >
                                                    <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                        <span
                                                            className={styles.icon}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <MoveIcon className={styles.iconComponent}/>
                                                        </span>

                                                        <EditableTextInput
                                                            id={'pendingTextBox'}
                                                            label={'Replace text input title'}
                                                            onChange={handleTextBoxChange}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>

                                    <Draggable
                                        draggableId={'textAreaInput'}
                                        index={4}
                                        key={'textAreaInput'}
                                    >
                                        {(provided, _snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                        userSelect: "none",
                                                        ...provided.draggableProps.style
                                                    }}
                                                >
                                                    <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                        <span
                                                            className={styles.icon}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <MoveIcon className={styles.iconComponent}/>
                                                        </span>

                                                        <EditableTextInput
                                                            id={'pendingTextAreaBox'}
                                                            label={'Replace text area title'}
                                                            onChange={handleTextAreaBoxChange}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>

                                    <Draggable
                                        draggableId={'radioInput'}
                                        index={5}
                                        key={'radioInput'}
                                    >
                                        {(provided, _snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                        userSelect: "none",
                                                        ...provided.draggableProps.style
                                                    }}
                                                >
                                                    <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                        <span
                                                            className={styles.icon}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <MoveIcon className={styles.iconComponent}/>
                                                        </span>
                                                        
                                                        <div className={styles.radioButtonContainer} >
                                                            <TextInput
                                                                className={styles.radioButtonLabel}
                                                                id={'pendingRadioTitle'}
                                                                label={'Radio Button Label'}
                                                                onChange={handleRadioButtonTitleChange}
                                                            />

                                                            {pendingRadioInputElementOptions && pendingRadioInputElementOptions.map(({label}, index) => {
                                                                return(
                                                                    <div className={styles.deleteContainer}>
                                                                        {index === 0 &&
                                                                            <RadioButtonWithTextInput handleTextChange={res => handleRadioButtonOptionChange(res, index)} id={'pendingRadioOne'} value={label}/>
                                                                        }

                                                                        {index !== 0 &&
                                                                            <RadioButtonWithTextInput handleTextChange={res => handleRadioButtonOptionChange(res, index)} value={label}/>
                                                                        }

                                                                        {index === pendingRadioInputElementOptions.length-1 && index !== 0 &&
                                                                            <Buttonize id="removeRadioButtonOption" onClick={res => handleRemoveRadioButtonOption(res, index)}>
                                                                                <CloseIcon />
                                                                            </Buttonize>
                                                                        }
                                                                    </div>
                                                                )
                                                            })}

                                                            <AddRadioButtonOption id="addRadioButtonOption" onClick={handleAddRadioButtonOption} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>

                                    <Draggable
                                        draggableId={'dropDownInput'}
                                        index={6}
                                        key={'dropDownInput'}
                                    >
                                        {(provided, _snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                        userSelect: "none",
                                                        ...provided.draggableProps.style
                                                    }}
                                                >
                                                    <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                        <span
                                                            className={styles.icon}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <MoveIcon className={styles.iconComponent}/>
                                                        </span>
                                                        
                                                        <div className={styles.dropDownContainer} >
                                                            <TextInput
                                                                className={styles.dropDownLabel}
                                                                id={'pendingDropDownTitle'}
                                                                label={'Dropdown Label'}
                                                                onChange={handleDropDownTitleChange}
                                                            />

                                                            {pendingDropDownInputElementOptions && pendingDropDownInputElementOptions.map((_, index) => {
                                                                return(
                                                                    <div className={styles.deleteContainer}>
                                                                        {index === 0 &&
                                                                            <TextInput
                                                                                className={styles.dropDownOption}
                                                                                id={'pendingDropDownOne'}
                                                                                onChange={res => handleDropDownOptionChange(res, index)}
                                                                                placeholder={"Option"}
                                                                            />
                                                                        }

                                                                        {index !== 0 &&
                                                                            <TextInput
                                                                                className={styles.dropDownOption}
                                                                                onChange={res => handleDropDownOptionChange(res, index)}
                                                                                placeholder={"Option"}
                                                                            />
                                                                        }
                                                                        
                                                                        {index === pendingDropDownInputElementOptions.length-1 && index !== 0 &&
                                                                            <Buttonize onClick={res => handleRemoveDropDownButtonOption(res, index)}>
                                                                                <CloseIcon />
                                                                            </Buttonize>
                                                                        }
                                                                    </div>
                                                                )
                                                            })}

                                                            <AddDropDownOption onClick={handleAddDropDownOption} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>

                                    <Draggable
                                        draggableId={'checkBoxInput'}
                                        index={7}
                                        key={'checkBoxInput'}
                                    >
                                        {(provided, _snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                        userSelect: "none",
                                                        ...provided.draggableProps.style
                                                    }}
                                                >
                                                    <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                        <span
                                                            className={styles.icon}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <MoveIcon className={styles.iconComponent}/>
                                                        </span>
                                                        
                                                        <div className={styles.radioButtonContainer} >
                                                            <TextInput
                                                                className={styles.radioButtonLabel}
                                                                id={'pendingCheckBoxTitle'}
                                                                label={'CheckBox Question'}
                                                                onChange={handleCheckBoxInputChange}
                                                            />

                                                            {pendingCheckBoxInputElementOptions && pendingCheckBoxInputElementOptions.map((_, index) => {
                                                                return(
                                                                    <div className={styles.deleteContainer}>
                                                                        {index === 0 &&
                                                                            <CheckBoxWithTextInput handleTextChange={res => handleCheckBoxOptionChange(res, index)} id={'pendingCheckBoxOne'} />
                                                                        }

                                                                        {index !== 0 &&
                                                                            <CheckBoxWithTextInput handleTextChange={res => handleCheckBoxOptionChange(res, index)}/>
                                                                        }
                                                                        
                                                                        {index === pendingCheckBoxInputElementOptions.length-1 && index !== 0 &&
                                                                            <Buttonize onClick={res => handleRemoveCheckBoxButtonOption(res, index)}>
                                                                                <CloseIcon />
                                                                            </Buttonize>
                                                                        }
                                                                    </div>
                                                                )
                                                            })}

                                                            <AddCheckBoxOption onClick={handleAddCheckBoxOption} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>

                                    <Draggable
                                        draggableId={'conditional'}
                                        index={8}
                                        key={'conditional'}
                                    >
                                        {(provided, _snapshot) => {
                                            const items = formElements.reduce((accumulator, element) => {
                                                if(element?.choices && element?.choices.length > 0){
                                                    accumulator.push({label: element.name, value: element.id})
                                                }
                                                return accumulator;
                                            }, []);
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                        userSelect: "none",
                                                        ...provided.draggableProps.style
                                                    }}
                                                >
                                                    <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                        <span
                                                            className={styles.icon}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <MoveIcon className={styles.iconComponent}/>
                                                        </span>
                                                        
                                                        <Dropdown id={'pendingConditional'} items={items} label={"Conditional Question"} onChange={setPendingConditionalElement} />
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>

                                    <Draggable
                                        draggableId={'table'}
                                        index={9}
                                        key={'table'}
                                    >
                                        {(provided, _snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        paddingBottom: 10,
                                                        paddingTop: 10,
                                                        userSelect: "none",
                                                        ...provided.draggableProps.style
                                                    }}
                                                >
                                                    <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                        <span
                                                            className={styles.icon}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <MoveIcon className={styles.iconComponent}/>
                                                        </span>
                                                        
                                                        <FormTable />
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>

                                    {provided.placeholder}
                                </div>
                            )
                        }}
                    </Droppable>
                </PanelBody>
            )}
        </ContentPanel>
    );
};

export default FormDetailsPanel;
