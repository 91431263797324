import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import ContentPanel from 'components/ContentPanel';
import DynamicTable from 'components/DynamicTable';
import Loader from 'components/Loader';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useData from './hooks/useData';
import useDataActivities from 'components/Activities/hooks/useData';

import normalizeData from './data';
import normalizeListData from 'components/Activities/data'

import styles from './styles.module.scss';

const ActivitiesReportPanel = ({ className = '' }) => {
    const { INTERNAL_SERVER_ERROR } = useConfig();
    const navigate = useNavigate();
    const { useActivities, useSchema, useQuerySchemas, useTenants, useUserId } = useGlobalStateHooks();
    const [activities, setActivities] = useActivities();
    const [querySchemas] = useQuerySchemas();
    const [userId] = useUserId();

    const [schema] = useSchema();
    const [tenants] = useTenants();

    const [chartData, setChartData] = useState();

    const { error, data } = useData();
    const { error: errorActivities, data: dataActivities } = useDataActivities();
    

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({ data, schema, tenants });

            setChartData(normalizedData)
        }
    }, [data, schema, setChartData, tenants]);

    useEffect(() => {
        if (dataActivities && (!activities || !('completed' in activities))) {
            const normalizedData = normalizeListData({
                data: dataActivities,
                isFlagged: true,
                querySchemas,
                schema,
                tenants,
                user: userId
            });
            setActivities(normalizedData);
        }
    // eslint-disable-next-line
    }, [dataActivities, querySchemas, tenants, schema, setActivities, userId]);

    if (error || errorActivities) {
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }
    
    return (
        <ContentPanel className={`${className} ${styles.reportsPanel}`}>
            {tenants && tenants.length!==1 && <Loader text={'Please select only one tenant to view the Activity report.'} />}

            {tenants && tenants.length===1 && chartData && 
                <DynamicTable
                    className={`${className} ${styles.table}`}
                    data={chartData}
                    defaultCharts={[
                        {alternativeTitle: 'Activity Count', column: ['name'], type: 'count'},
                        {alternativeTitle: 'Activities Assigned to Users', column: ['userActivities', 'label'], type: 'pie'},
                        {alternativeTitle: 'Activity Risk Labels', column: ['riskLabel'], type: 'doughnut'},
                        {alternativeTitle: 'Activity Types', column: ['activityType'], type: 'doughnut'}
                    ]}
                    hasCustomDateDropdown
                    hasMultitenant={tenants && tenants.length > 1}
                />
            }
        </ContentPanel>
    );
};

export default ActivitiesReportPanel;
