import { useCallback } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import remap from 'utilities/remap';

import styles from './styles.module.scss';

import isFunction from 'utilities/isFunction';

const DonutChart = ({ className = '', donut=true, items = [], onClick }) => {
    const dataItems = remap({ items, map: { text: 'title' } });

    const applyLabelStyles = useCallback((index) => ({
            fill: items[index].textColor,
            fontFamily: 'sans-serif',
            fontSize: '5px'
        }),
        [items]
    );

    const handleClick = useCallback((event) => {
        if (isFunction(onClick)) {
            onClick(event.target.textContent);
        }
        // setSelected(index === selected ? undefined : index);
    }, [onClick]);

    const handleMouseOver = useCallback(_ => {
        // setHovered(index);
    }, []);

    const handleMouseOut = useCallback(() => {
        // setHovered(undefined);
    }, []);

    return (
        <PieChart
            animate
            className={`${className} ${styles.bodyText}`}
            data={dataItems}
            label={({ dataEntry }) => dataEntry.value}
            labelPosition={80}
            labelStyle={applyLabelStyles}
            lineWidth={donut ? 40 : 100}
            onClick={handleClick}
            onMouseOut={handleMouseOut}
            onMouseOver={handleMouseOver}
            radius={
                PieChart?.defaultProps?.radius
                    ? PieChart.defaultProps.radius - 6
                    : undefined
            }
            segmentsStyle={{ cursor: 'pointer', transition: 'stroke .3s' }}
            // segmentsShift={index => (index === selected ? 6 : 1)}
        />
    );
};

export default DonutChart;
