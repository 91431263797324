import isFunction from 'utilities/isFunction';
import styles from './styles.module.scss';

const MoveIcon = ({ className = '', onClick }) => {
    const handleClick = () => {
        if(isFunction(onClick)){
            onClick();
        }
    };
    return (
        <svg
            className={`${className} ${styles.moveIcon}`}
            fill="none"
            height="24"
            onClick={handleClick}
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
        </svg>
    )
};

export default MoveIcon;
