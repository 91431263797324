import { gql } from '@apollo/client';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import state from 'app/state';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useGraphQL from 'hooks/useGraphQL';

import sendMarketingAssignedEmail from 'utilities/sendMarketingAssignedEmail';
import { useInsertMarketingRequestForm } from './useInsertMarketingRequestForm';

const { addRequest } = state;

const { addRequestAtom } = addRequest.atoms;

const MUTATE_REQUEST = tenantSchema => gql`
    mutation insertRequest (
        $userId: Int!,
        $status: String!,
        $requestName: String!,
        $dueDate: timestamp = "",
        $expectedUseDate: timestamp = "",
        $recipient: String!,
        $dropdownSelections: jsonb!,
        $highImportance: Boolean!,
        $description: String!
    ) {
        insert_${tenantSchema}_marketing_request(
            objects: {
                user_id: $userId,
                status: $status,
                request_name: $requestName,
                due_date: $dueDate,
                expected_use_date: $expectedUseDate,
                recipient: $recipient,
                dropdown_selections: $dropdownSelections,
                high_importance: $highImportance,
                description: $description
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_REQUEST_VERSION = tenantSchema => gql`
    mutation insertRequestVersion(
        $requestId: Int!,
        $versionNumber: Int!,
        $userId: Int!,
        $file: String,
        $status: String!
    ) {
        insert_${tenantSchema}_marketing_request_version(objects: {
            request_id: $requestId,
            version_number: $versionNumber,
            created_by: $userId,
            file: $file,
            file_name: $file,
            status: $status
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_REQUEST_ASSIGNED_TO = tenantSchema => gql`
    mutation insertActivityPolicy(
        $requestId: Int!,
        $userId: Int,
        $groupId: Int
    ) {
        insert_${tenantSchema}_marketing_request_assigned_to(objects: {
            request_id: $requestId,
            user_id: $userId,
            group_id: $groupId
        }) {
            returning {
                id
            }
        }
    }
`;


const MUTATE_MARKETING = tenantSchema => gql`
mutation insertCaseFormAnswer($answer: String!, $formId: Int!, $marketingId: Int!, $questionId: Int!, $userId: Int! ) {
    insert_${tenantSchema}_marketing_form_answers(objects: {
        form_id: $formId,
        marketing_id: $marketingId,
        user_id: $userId,
        question_id: $questionId
        answer: $answer
    }) {
      returning {
        id
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_REQUEST, providedSchema);
    const mutationVersion = useGraphQL(MUTATE_REQUEST_VERSION, providedSchema);
    const mutationAssignedTo = useGraphQL(MUTATE_REQUEST_ASSIGNED_TO, providedSchema);
    const mutationMarketingForm = useGraphQL(MUTATE_MARKETING, providedSchema)
		const insertMarketingRequestForm = useInsertMarketingRequestForm(providedSchema)

    const { API_KEY } = useConfig();
    const { useSchema, useSchemaDescription, useTenants, useUserSetSchema } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userSetSchema] = useUserSetSchema();

    const usedSchema = providedSchema ? providedSchema : userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
    const [schemaDescription] = useSchemaDescription();
    const usedSchemaDescription = providedSchema ? providedSchema : userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schemaDescription;

    const saveRequest = useRecoilCallback(({ set }) => async (variables) => {
        const runMutation = async () => {
            const res = await mutation(variables);

            const id = res?.data[`insert_${usedSchema}_marketing_request`].returning[0]?.id;
            variables.requestId = id;
            variables.versionNumber = 1;

						if(variables?.variableForm?.formId){
							await insertMarketingRequestForm({
								formId: variables?.variableForm?.formId,
								marketingRequestId: id
							})
						}

            await mutationVersion(variables);

            for (let assignee of variables?.assignedTo) {
                await mutationAssignedTo({
                    groupId: (assignee?.type==='group') ? assignee?.id : null,
                    requestId: id,
                    userId: (assignee?.type==='user') ? assignee?.id : null
                });
                if(assignee?.type==='user'){
                    sendMarketingAssignedEmail(id, variables?.requestName, new Date(variables?.dueDate), null, usedSchema, usedSchemaDescription, assignee?.id, API_KEY ? API_KEY : '')
                }else if(assignee?.type==='group'){
                    sendMarketingAssignedEmail(id, variables?.requestName, new Date(variables?.dueDate), assignee?.id, usedSchema, usedSchemaDescription, null, API_KEY ? API_KEY : '')
                }
            }

            for (const answer of variables?.variableForm?.answers){
              await mutationMarketingForm({
                answer: answer?.answer,
                formId: variables?.variableForm?.formId,
                marketingId: id,
                questionId: parseInt(answer?.questionId),
                userId: variables?.variableForm?.userId
              });
            }

            set(addRequestAtom, id);
        }

        await runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addRequestAtom, '');
    });

    return [useRecoilValue(addRequestAtom), saveRequest, reset];
};

export default useData;
