import { useState, useCallback, useRef } from "react";

export const useAsyncLock = (asyncFunction) => {
    const [isLoading, setIsLoading] = useState(false);
    const isRunningRef = useRef(false);

    const execute = useCallback(async (...args) => {
        if (isRunningRef.current) return;

        isRunningRef.current = true;
        setIsLoading(true);

        try {
            const result = await asyncFunction(...args);
            return result;
        } catch (error) {
            throw error;
        } finally {
            isRunningRef.current = false;
            setIsLoading(false);
        }
    }, [asyncFunction]);

    return [execute, isLoading];
};
