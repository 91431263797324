import { useCallback, 
    useState, 
    useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CancelSaveButtons from 'components/CancelSaveButtons';
import WithPageTitle from 'components/WithPageTitle';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';

import useCreateActivityType from './hooks/useCreateActivityType';
import useTranslation from './hooks/useTranslations';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useLabelData from './hooks/useLabelData';

import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';
import TextInput from 'components/TextInput';
import CustomRadioButtons from 'components/CustomRadioButtons';

import styles from './styles.module.scss';

import normalizeData from './labelData';

const AddActivityType = () => {
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const { loading, error, data } = useLabelData();

    const { 
        ADMIN
    } = useConfig();

    const navigate = useNavigate();

    const { ACTIVITY_TYPE_NAME, ADD_NEW_ACTIVITY_TYPE, RISK_LABEL } = useTranslation();

    const [createdActivityType, 
        createActivityType, 
        resetCreateActivityType] = useCreateActivityType();

    const [riskLevelOptions, setRiskLevelOptions] = useState();
    const [selectedRiskLevel, setSelectedRiskLevel] = useState();

    const form = useForm({
        name: '',
        riskLevel: '',
    });

    const { 
        handleForm,
        handleError,
        handleValid,
        handleSubmit
     } = form;

    const handleCancel = useCallback(() => {
        navigate(ADMIN);
    }, [ADMIN, navigate]);

    const handleSave = useCallback(() =>{
        const output = handleForm();

        if (typeof output === 'string') {
            return;
        }

        const formObject = Array.from(output.entries()).reduce(
            (accumulator, [key, value]) => {
                accumulator[key] = value;
                return accumulator;
            }, {}
        );

        formObject.labelId = selectedRiskLevel;
        formObject.riskLevel = riskLevelOptions.find(el => el.id === selectedRiskLevel)?.labelName ? riskLevelOptions.find(el => el.id === selectedRiskLevel)?.labelName : '';

        createActivityType(formObject);
        }, [createActivityType, handleForm, selectedRiskLevel]
    );

    useEffect(() => {
        if (!createdActivityType) {
            return;
        }

        navigate(ADMIN);

        resetCreateActivityType();
    }, [createdActivityType, navigate, resetCreateActivityType, ADMIN]);


    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });
            if (normalizedData) {
                setRiskLevelOptions(normalizedData);
            };
        };
    }, [data, schema, setRiskLevelOptions])

    if (loading) {
        return `Loading...`;
    }
    if (error) {
        return `Error! ${error}`;
    };

    return (
        <div className={styles.addActivityType}>
            <WithPageTitle title={ADD_NEW_ACTIVITY_TYPE}>
                <CancelSaveButtons onCancel={handleCancel} onSave={handleSave} />
            </WithPageTitle>

            <ContentPanel>
                <PanelBody className={styles.panelBody}>
                    <TextInput
                        label={ACTIVITY_TYPE_NAME}
                        onChange={handleValid('name')}
                        onError={handleError('name')}
                        onSubmit={handleSubmit('name')}
                        placeholder={'Enter the Activity Name'}
                        value={""}
                    />

                    <CustomRadioButtons
                        className={styles.riskLevelRadioButtons}
                        label={RISK_LABEL}
                        onChange={setSelectedRiskLevel}
                        options={riskLevelOptions}
                    />
                </PanelBody>
            </ContentPanel>
        </div>
    );
};

export default AddActivityType;
