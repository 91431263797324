import { memo, useCallback, useEffect, useState } from 'react';

import BodyText from 'components/BodyText';
import RadioButtons from 'components/RadioButtons';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';
import titleCase from 'utilities/titleCase';

const CustomRadioButtons = ({ className = '', label, onChange, options}) => {
    const handleChange = useCallback(
        (_, radioButtonLabel, radioStates) => {
            if (isFunction(onChange)) {
                onChange?.(radioButtonLabel, radioStates);
            }
        },
        [onChange]
    );

    const [usedOptions, setUsedOptions] = useState();

    useEffect(() => {
        const settingOptions = [];
        if(options){
            for(const option of options){
                const optionStyle = option?.labelColor === 'green' ? styles.low
                                : option?.labelColor === 'blue' ? styles.medium
                                : option?.labelColor === 'orange' ? styles.high
                                : option?.labelColor === 'red' ? styles.critical
                                : ''
                settingOptions.push({
                    className: optionStyle,
                    name: option?.labelName,
                    text: titleCase(option?.labelName),
                    value: option.id,
                })
            }
        }

        setUsedOptions(settingOptions);
    }, [options])

    return (
        <div className={`${className} ${styles.riskLevelRadioButtons}`}>
            <BodyText className={className} text={label} />

            <RadioButtons
                className={styles.radioButtons}
                items={usedOptions}
                onChange={handleChange}
            />
        </div>
    );
};

export default memo(CustomRadioButtons);
