import { useState, useEffect } from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import { v4 as uuid } from 'uuid';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import FormDetailsPanel from 'components/FormDetailsPanel';
import FormMetaPanel from 'components/FormMetaPanel';
import PanelWithSplitPanel from 'components/PanelWithSplitPanel';

import styles from './styles.module.scss';

const FormDetailsTab = ({ form }) => {
    const { useSchema, useTenants } = useGlobalStateHooks();
    const [isEditing, setIsEditing] = useState();

    const [schema] = useSchema();
    const [tenants] = useTenants();

    const usedSchema = tenants.length === 1 ? tenants[0] : schema;

    const [formElements, setFormElements] = useState([]);
    const [pendingTitleElement, setPendingTitleElement] = useState();
    const [pendingDescriptionElement, setPendingDescriptionElement] = useState();
    const [pendingTextInputElement, setPendingTextInputElement] = useState();
    const [pendingTextAreaInputElement, setPendingTextAreaInputElement] = useState();
    const [pendingRadioInputElement, setPendingRadioInputElement] = useState();
    const [pendingRadioInputElementOptions, setPendingRadioInputElementOptions] = useState([{label: '', name: ''}]);
    const [pendingDropDownInputElement, setPendingDropDownInputElement] = useState();
    const [pendingDropDownInputElementOptions, setPendingDropDownInputElementOptions] = useState([{label: '', value: ''}]);
    const [pendingCheckBoxInputElement, setPendingCheckBoxInputElement] = useState();
    const [pendingCheckBoxInputElementOptions, setPendingCheckBoxInputElementOptions] = useState([{label: '', value: ''}]);
    const [pendingConditionalElement, setPendingConditionalElement] = useState();
    const [resetElement, setResetElements] = useState(false);

    const onDragEnd = (result) => {
        const formComponent = result?.destination?.droppableId;
        const formIndex = result?.destination?.index;
        const sourceComponent = result?.source?.droppableId;
        const sourceIndex = result?.source?.index;

        const resortedElements = [...formElements];

        let newFormElements;

        if(formComponent !== 'formElements'){
            if(sourceComponent === 'newForm'){
                for (let i = 0; i < resortedElements.length; i++) {
                    if(resortedElements[i]['index'] > formIndex && resortedElements[i]['formComponent'] === formComponent){
                        const elem = {...resortedElements[i]};
                        elem['index'] = elem['index'] + 1;
                        resortedElements[i]=elem;
                    }
                }
                const modifiedElementIndex = resortedElements.map(e => e.id).indexOf(result?.draggableId);
                const modifiedElement = resortedElements[modifiedElementIndex];
                resortedElements.splice(modifiedElementIndex, 1);
                for (let i = 0; i < resortedElements.length; i++) {
                    if(resortedElements[i]['formComponent'] === formComponent){
                        if(resortedElements[i]['index'] > sourceIndex && resortedElements[i]['formIndex'] <= formIndex){
                            resortedElements[i]['index'] = resortedElements[i]['index'] - 1;
                        }else if(resortedElements[i]['index'] < sourceIndex && resortedElements[i]['index'] >= formIndex){
                            resortedElements[i]['index'] = resortedElements[i]['index'] + 1;
                        }
                    }
                }
                resortedElements.push({
                    formComponent: modifiedElement?.formComponent,
                    id: modifiedElement?.questionId,
                    index: formIndex,
                    choices: modifiedElement?.choices,
                    name: modifiedElement?.name,
                    type: modifiedElement?.type
                });
                newFormElements = resortedElements;
                console.log(newFormElements)
            }else{
                if (result.draggableId === 'titleText'){
                    const elementsWithAddedTitle = resortedElements
                    elementsWithAddedTitle.push({
                        formComponent: formComponent,
                        id: `titleText${uuid()}`,
                        index: formIndex,
                        choices: [],
                        name: pendingTitleElement,
                        type: 'title'
                    })
                    newFormElements = elementsWithAddedTitle;
                }else if (result.draggableId === 'normalText'){
                    const elementsWithAddedDescription = resortedElements
                    elementsWithAddedDescription.push({
                        formComponent: formComponent,
                        id: `normalText${uuid()}`,
                        index: formIndex,
                        choices: [],
                        name: pendingDescriptionElement,
                        type: 'text'
                    })
                    newFormElements = elementsWithAddedDescription;
                }else if (result.draggableId === 'textInput'){
                    const elementsWithAddedTextBox = resortedElements
                    elementsWithAddedTextBox.push({
                        formComponent: formComponent,
                        id: `textInput${uuid()}`,
                        index: formIndex,
                        choices: [],
                        name: pendingTextInputElement,
                        type: 'textInput'
                    })
                    newFormElements = elementsWithAddedTextBox;
                }else if (result.draggableId === 'textAreaInput'){
                    const elementsWithAddedTextAreaBox = resortedElements
                    elementsWithAddedTextAreaBox.push({
                        formComponent: formComponent,
                        id: `textAreaInput${uuid()}`,
                        index: formIndex,
                        choices: [],
                        name: pendingTextAreaInputElement,
                        type: 'textAreaInput'
                    })
                    newFormElements = elementsWithAddedTextAreaBox;
                }else if (result.draggableId === 'radioInput'){
                    const elementsWithAddedRadioButtons = resortedElements
                    elementsWithAddedRadioButtons.push({
                        formComponent: formComponent,
                        id: `radioInput${uuid()}`,
                        index: formIndex,
                        choices: pendingRadioInputElementOptions,
                        name: pendingRadioInputElement,
                        type: 'radioInput'
                    })
                    newFormElements = elementsWithAddedRadioButtons;
                }else if (result.draggableId === 'dropDownInput'){
                    const elementsWithAddedDropDown = resortedElements
                    elementsWithAddedDropDown.push({
                        formComponent: formComponent,
                        id: `dropDownInput${uuid()}`,
                        index: formIndex,
                        choices: pendingDropDownInputElementOptions,
                        name: pendingDropDownInputElement,
                        type: 'dropDownInput'
                    })
                    newFormElements = elementsWithAddedDropDown;
                }else if (result.draggableId === 'checkBoxInput'){
                    const elementsWithAddedCheckbox = resortedElements
                    elementsWithAddedCheckbox.push({
                        formComponent: formComponent,
                        id: `checkBoxInput${uuid()}`,
                        index: formIndex,
                        choices: pendingCheckBoxInputElementOptions,
                        name: pendingCheckBoxInputElement,
                        type: 'checkBoxInput'
                    })
                    newFormElements = elementsWithAddedCheckbox;
                }else if (result.draggableId === 'conditional'){
                    const elementsWithAddedConditional = resortedElements;
                    const conditionalForms = resortedElements.filter(x => x.id === pendingConditionalElement)[0];
                    const conditionalOptions = conditionalForms?.choices;
                    const conditionalName = conditionalForms?.name;
                    elementsWithAddedConditional.push({
                        formComponent: formComponent,
                        id: `conditional${uuid()}`,
                        index: formIndex,
                        choices: conditionalOptions,
                        name: conditionalName,
                        type: 'conditional'
                    })
                    newFormElements = elementsWithAddedConditional;
                }else if (result.draggableId === 'table') {
                    const elementsWithAddedTable = resortedElements;
                    elementsWithAddedTable.push({
                        formComponent: formComponent,
                        id: `table-${uuid()}`,
                        index: formIndex,
                        text: 'Table',
                        type: 'table'
                    });
                    newFormElements = elementsWithAddedTable;
                } else{
                    newFormElements = resortedElements;
                }
                setPendingTitleElement('');
                setPendingDescriptionElement('');
                setPendingTextInputElement('');
                setPendingTextAreaInputElement('');
                setPendingRadioInputElement('');
                setPendingRadioInputElementOptions([{label: '', name: ''}]);
                setPendingDropDownInputElement('');
                setPendingDropDownInputElementOptions([{label: '', value: ''}]);
                setPendingCheckBoxInputElement('');
                setPendingCheckBoxInputElementOptions([{label: '', value: ''}]);
                setPendingConditionalElement('');
                setResetElements(true);
            }
        }
        console.log(newFormElements.sort((a, b) => a.index - b.index))
        setFormElements(newFormElements.sort((a, b) => a.index - b.index))
    };

    useEffect(() => {
        if(form?.questions){
            const formQuestions = form?.questions.reduce((accumulator, question) => {
                accumulator.push({
                    ...question?.question,
                    id: question?.question?.questionId,
                    index: question?.question?.index +1
                });
                return accumulator;
            }, [])
            setFormElements(formQuestions);
        }
    }, [form])

    return(
        <PanelWithSplitPanel>
            <DragDropContext
                onDragEnd={result => onDragEnd(result)}
            >
                <FormMetaPanel
                    className={styles.formMetaPanel}
                    form={form}
                    formElements={formElements}
                    isEditing={isEditing}
                    setFormElements={setFormElements}
                    setIsEditing={setIsEditing}
                    usedSchema={usedSchema}
                />

                <FormDetailsPanel
                    form={form}
                    formElements={formElements}
                    isEditing={isEditing}
                    pendingCheckBoxInputElementOptions={pendingCheckBoxInputElementOptions}
                    pendingDropDownInputElementOptions={pendingDropDownInputElementOptions}
                    pendingRadioInputElementOptions={pendingRadioInputElementOptions}
                    resetElement={resetElement}
                    setIsEditing={setIsEditing}
                    setPendingCheckBoxInputElement={setPendingCheckBoxInputElement}
                    setPendingCheckBoxInputElementOptions={setPendingCheckBoxInputElementOptions}
                    setPendingConditionalElement={setPendingConditionalElement}
                    setPendingDescriptionElement={setPendingDescriptionElement}
                    setPendingDropDownInputElement={setPendingDropDownInputElement}
                    setPendingDropDownInputElementOptions={setPendingDropDownInputElementOptions}
                    setPendingRadioInputElement={setPendingRadioInputElement}
                    setPendingRadioInputElementOptions={setPendingRadioInputElementOptions}
                    setPendingTextAreaInputElement={setPendingTextAreaInputElement}
                    setPendingTextInputElement={setPendingTextInputElement}
                    setPendingTitleElement={setPendingTitleElement}
                    setResetElements={setResetElements}
                />
            </DragDropContext>
        </PanelWithSplitPanel>
    )
};

export default FormDetailsTab;
