export const activityFromDays = -90;
export const activityToDays = 90;

export const getActivityDateFilter = () => ({
    activityDateFrom: new Date(
        new Date().setDate(new Date().getDate() + activityFromDays)
    ),
    activityDateTo: new Date(
        new Date().setDate(new Date().getDate() + activityToDays)
    )
});
