const normalizeData = ({ data, schema }) =>
    data[`${schema}_labels`].reduce((accumulator, label) => {
        const {   
            id,
            label_name,
            label_color
        } = label;

        accumulator.push({
            id,
            labelName: label_name,
            labelColor: label_color
        });

        return accumulator;
    }, []);

export default normalizeData;
