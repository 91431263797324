const formComponentSplitter = '_';
const formComponetIdJoiner = '-';

export function getFormComponentParts(formComponent = '') {
    const [formComponentType, questionId, componentId] = formComponent.split(
        formComponentSplitter
    );

    return {
        componentId: sanityzeComponentId(componentId),
        formComponentType,
        questionId
    };
}

export function sanityzeComponentId(sanityzeComponentId = '') {
    return sanityzeComponentId
        .replace(/\s/g, formComponetIdJoiner)
        .toLowerCase();
}
