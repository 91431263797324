import Carousel from 'components/Carousel';
import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';

import translations from './constants';

import ActivitiesStatistics from 'components/ActivitiesStatistics';

import styles from './styles.module.scss';

import { useRecoilValue } from 'recoil';
import { activitiesDashboardCountsState } from '../../states/activities.state';

const overdueCap = value => {
    return value > 100 ? '100+' : value;
};

const GlancePanel = ({ className = '' }) => {
    const activitiesDashboardCounts = useRecoilValue(activitiesDashboardCountsState);
    const isLoading = new Set(Object.values(activitiesDashboardCounts)).size === 1 && activitiesDashboardCounts.pending === 0;
    const { GLANCE } = translations();


    return (
        <ContentPanel className={`${className} ${styles.glancePanel}`}>
            <PanelBody
                className={styles.panelBody}
                isLoading={isLoading}
            >
                <PanelTitle text={GLANCE} />

                <Carousel
                    className={styles.carousel}
                    items={[
                        <ActivitiesStatistics
                            completeCount={activitiesDashboardCounts.done}
                            inProgressCount={activitiesDashboardCounts.pending}
                            overdueCount={overdueCap(activitiesDashboardCounts.overdue)}
                            upcomingCount={activitiesDashboardCounts.upcoming}
                        />
                    ]}
                />
            </PanelBody>
        </ContentPanel>
    );
};

export default GlancePanel;
